<template lang="pug">
  .reservations-tab
    .chart
      LineChart(
        :x-values="dates"
        :y-values="reservationValues"
        name="Reservations"
      )
    .tip
      FaIcon(icon="info-circle")
      | {{ $t("price_calendar.calendar.price_modal.reservations_tip") }}
</template>

<script>
  import { keys, values } from "lodash-es"

  export default {
    props: {
      startDate: Date,
      reservations: {
        type: Object,
        default: () => new Object()
      }
    },

    components: {
      LineChart: () => import("./LineChart")
    },

    computed: {
      dates() {
        return keys(this.reservations)
      },

      reservationValues() {
        return values(this.reservations)
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/mixins/price-calendar.sass"

  .reservations-tab
    .chart
      margin: 0 auto
      width: 95%

    .tip
      +tip-with-icon
      text-align: right
      margin-top: 5px
</style>
